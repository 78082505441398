import React from 'react';
import moment from 'moment';
import ModalImage from 'react-modal-image';
import { deleteMessage } from '../../../../../korper-redux/actions'
import { connect } from 'react-redux'
import axios from 'axios'
import './Message.css';

function Message(props) {
  const {
    data,
    isMine,
    startsSequence,
    endsSequence,
    showTimestamp
  } = props;

  const friendlyTimestamp = moment(data.createdAt).format('LLLL');
  const mediaOnly = (data.image || data.video) && !data.text

  const onBubbleClicked = (e) => {
    // console.log('Click')
    if (e.shiftKey) {
      // console.log('Deleting', data._id)
      try {
        axios.delete(`https://online.korper.io/coachs/messages/${data._id}`, { withCredentials: true })

        props.deleteMessage(data._id)
      } catch (err) {
        console.error(err)
      }
    }
  }

  return (
    <div className={[
      'message',
      `${isMine ? 'mine' : ''}`,
      `${startsSequence ? 'start' : ''}`,
      `${endsSequence ? 'end' : ''}`
    ].join(' ')}>
      {
        showTimestamp &&
        <div className="timestamp">
          {friendlyTimestamp}
        </div>
      }

      <div className="bubble-container">
        <div className="bubble" title={friendlyTimestamp} style={{ padding: mediaOnly ? 0 : undefined }} onClick={onBubbleClicked}>
          {data.image && (
            <ModalImage
              small={data.image}
              large={data.image}
              hideDownload
              className="image-preview"
            />
          )}
          {data.video && (
            <video className="video-preview" controls>
              <source src={data.video} />
            </video>
          )}
          {data.text && <span>{data.text}</span>}
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = { deleteMessage }

export default connect(null, mapDispatchToProps)(Message);